
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import MediaHubFilters from "@/components/mediaHub/MediaHubFilters"
import MediaHubGalleryPlaceholder from "@/components/mediaHub/MediaHubGalleryPlaceholder"
import MediaHubGallery from "@/components/mediaHub/MediaHubGallery"
import Vue from "vue"
import { updateQuery, toQueryString } from "@evercam/shared/utils"
import { camelizeKeys } from "humps"
import {
  Media,
  MediaFilterQueryParams,
  MediaStatus,
  MediaType,
} from "@evercam/shared/types/media"
import { Camera } from "@evercam/shared/types/camera"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, PaginatedItems } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"

export default Vue.extend({
  name: "MediaHubContainer",
  components: {
    RightSidebarContent,
    MediaHubFilters,
    MediaHubGalleryPlaceholder,
    MediaHubGallery,
  },
  data() {
    return {
      loading: true,
      isRefreshingMediaHub: false,
      filters: {
        requesterEmail: "" as string | undefined,
        camera: {} as Partial<Camera> | undefined,
        type: "",
        fromDate: this.$moment(new Date().toISOString())
          .subtract({ years: 5 })
          .format("YYYY-MM-DD"),
        toDate: this.$moment(new Date()).format("YYYY-MM-DD"),
        sortBy: "created_at|desc",
      },
      currentPage: 1,
    }
  },
  computed: {
    ...mapStores(
      useMediaHubStore,
      useProjectStore,
      useCameraStore,
      useLayoutStore
    ),
  },
  created() {
    this.updateFilterFromUrl()
    this.onTimelapseCreationCompleted()
  },
  mounted() {
    this.layoutStore.enableRightSidebar()
    this.currentPage = parseInt(this.$route.query.page as string) || 1
    this.getAllMedia(this.currentPage)
  },
  beforeDestroy() {
    this.mediaHubStore.$reset()
    this.layoutStore.disableRightSidebar()
  },
  methods: {
    applyFilters() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubApplyFilters)
      this.currentPage = 1
      this.getAllMedia()
    },
    refreshMediaHub() {
      this.isRefreshingMediaHub = true
      this.getAllMedia()
    },
    async getAllMedia(page = 1) {
      let items: Array<Media> = []
      const format = "YYYY-MM-DD"
      try {
        this.loading = true

        const fromDate = this.$moment(this.filters.fromDate).format(format)
        const toDate = this.$moment(this.filters.toDate).format(format)
        const params: MediaFilterQueryParams = {
          cameraExid: this.filters.camera?.id,
          requesterEmail: this.filters.requesterEmail,
          type: this.filters.type as MediaType,
          dateRange: [fromDate, toDate],
          sort: this.filters.sortBy,
          page: page,
          limit: 12,
        }

        updateQuery(params)

        const processedParams: MediaFilterQueryParams = Object.entries(
          params
        ).reduce(
          (acc, [key, value]: [any, any]) =>
            !["", null, undefined].includes(value)
              ? { ...acc, [key]: value }
              : acc,
          {}
        )

        if (
          this.mediaHubStore.filterParamsStringify ===
            toQueryString(processedParams) &&
          !this.isRefreshingMediaHub
        ) {
          return
        }

        this.mediaHubStore.filterParamsStringify =
          toQueryString(processedParams)
        const response: PaginatedItems<Media> =
          await EvercamApi.mediaHub.getAllMedia(
            this.projectStore.selectedProjectExid,
            processedParams
          )
        this.mediaHubStore.totalMediaItems = response.total
        items = response.items
      } catch (e) {
        this.$errorTracker.save(e)
      } finally {
        this.loading = false
      }
      this.isRefreshingMediaHub = false
      this.mediaHubStore.mediaItems = items
    },
    fetchPage(page: number) {
      if (!this.loading) {
        this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectPage)
        this.currentPage = page
        this.getAllMedia(page)
      }
    },
    updateFilterFromUrl() {
      const queryParams = camelizeKeys(
        this.$route.query
      ) as unknown as MediaFilterQueryParams
      let filters = {} as MediaFilterQueryParams
      if (queryParams.sort) {
        filters.sortBy = queryParams.sort
      }

      const dateRange = queryParams.dateRange ? queryParams.dateRange : []
      if (dateRange.length) {
        filters.fromDate = dateRange[0]
        filters.toDate = dateRange[1]
      }

      if (queryParams?.cameraExid) {
        filters.camera = this.cameraStore.cameras?.find(
          (camera) => camera.id === queryParams.cameraExid
        )
      }

      this.filters = {
        ...this.filters,
        requesterEmail: queryParams?.requesterEmail,
        type: queryParams?.type || "",
        ...filters,
      }
    },
    onTimelapseCreationCompleted() {
      this.$root.$on("timelapse-creation-completed", (mediaItem) => {
        if (mediaItem.status === "success") {
          this.mediaHubStore.mediaItems = this.mediaHubStore.mediaItems.map(
            (item) => {
              if (item.exid === mediaItem.exid) {
                return {
                  ...item,
                  status: MediaStatus.Completed,
                  url: this.getThumbnailUrl(mediaItem),
                }
              }

              return item
            }
          )
          this.$notifications.success(
            this.$t("content.timelapse.creation_success")
          )
        } else {
          this.mediaHubStore.filterParamsStringify = null
          this.getAllMedia()
          this.$notifications.success(
            "Sorry, we could not process your request"
          )
        }
      })
    },
    getThumbnailUrl(mediaItem) {
      return `${this.$config.public.apiURL}/projects/${this.projectStore.selectedProjectExid}/media-hub/${mediaItem.exid}/thumbnail?type=${MediaType.Timelapse}`
    },
  },
})
