
import {
  MediaFilterQueryParams,
  MediaType,
  MediaUsersResponsePayload,
} from "@evercam/shared/types/media"
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent, Camera } from "@evercam/shared/types"
import MediaCreationDialog from "@/components/mediaHub/MediaCreationDialog"

type DateMenuRef = Vue & { save: (date: string) => void }

export default Vue.extend({
  name: "MediaHubFilters",
  components: {
    Autocomplete,
    MediaCreationDialog,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      users: [] as MediaUsersResponsePayload,
      filters: {} as MediaFilterQueryParams,
      startDateMenu: false,
      endDateMenu: false,
      types: [
        {
          text: this.$t("content.media_hub.media_type.all"),
          value: "",
        },
        {
          text: this.$t("content.media_hub.media_type.clip"),
          icon: "fas fa-cloud",
          value: MediaType.Clip,
        },
        {
          text: this.$t("content.media_hub.media_type.local_clip"),
          icon: "fas fa-video",
          value: MediaType.LocalClip,
        },
        {
          text: this.$t("content.media_hub.media_type.compare"),
          icon: this.$device.isIos
            ? "fas fa-compress"
            : "fas fa-compress-arrows-alt",
          value: MediaType.Compare,
        },
        {
          text: this.$t("content.media_hub.media_type.url"),
          icon: "fas fa-link",
          value: MediaType.ExternalUrl,
        },
        {
          text: this.$t("content.media_hub.media_type.file"),
          icon: "fas fa-upload",
          value: MediaType.File,
        },
        {
          text: this.$t("content.media_hub.media_type.xray"),
          icon: "fas fa-x-ray",
          value: MediaType.XRay,
        },
        {
          text: this.$t("content.media_hub.media_type.edit"),
          icon: "fas fa-images",
          value: MediaType.EditedImage,
        },
        {
          text: this.$t("content.media_hub.media_type.timelapse"),
          icon: this.$device.isIos ? "fas fa-clock" : "fas fa-history",
          value: MediaType.Timelapse,
        },
      ],
      sortParameters: [
        {
          text: this.$t("content.media_hub.sort_by.first_created"),
          value: "created_at|desc",
        },
        {
          text: this.$t("content.media_hub.sort_by.last_created"),
          value: "created_at|asc",
        },
        {
          text: this.$t("content.media_hub.sort_by.title_asc"),
          value: "title|asc",
        },
        {
          text: this.$t("content.media_hub.sort_by.title_desc"),
          value: "title|desc",
        },
        {
          text: this.$t("content.media_hub.sort_by.author_asc"),
          value: "requester_name|asc",
        },
        {
          text: this.$t("content.media_hub.sort_by.author_desc"),
          value: "requester_name|desc",
        },
        {
          text: this.$t("content.media_hub.sort_by.type"),
          value: "type",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useCameraStore),
    startDateMenuRef(): DateMenuRef {
      return this.$refs.form as DateMenuRef
    },
    endDateMenuRef(): DateMenuRef {
      return this.$refs.form as DateMenuRef
    },
    formattedStartDate(): string {
      let date
      if (this.filters.fromDate) {
        date = this.$moment(this.filters.fromDate).format("DD/MM/YYYY")
      } else {
        date = this.$moment(new Date())
          .subtract({ months: 2 })
          .format("DD/MM/YYYY")
      }

      return date
    },
    formattedEndDate(): string {
      return this.$moment(this.filters.toDate || new Date()).format(
        "DD/MM/YYYY"
      )
    },
    projectCameras(): Camera[] {
      return (this.cameraStore.cameras?.filter(
        (camera) => camera.project.id === this.projectStore.selectedProjectExid
      ) || []) as Camera[]
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.filters = { ...this.filters, ...this.value }
      },
    },
    "filters.camera"(value, oldValue) {
      if (value?.exid === oldValue?.exid) {
        return
      }
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "camera",
        value: value?.exid,
      })
    },
    "filters.type"(v) {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "mediaType",
        value: v,
      })
    },
    "filters.requesterEmail"() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "requesterEmail",
      })
    },
    "filters.sortBy"(v) {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "sortBy",
        value: v,
      })
    },
    "filters.fromDate"() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "fromDate",
      })
    },
    "filters.toDate"() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubSelectFilter, {
        type: "toDate",
      })
    },
  },
  mounted() {
    this.fetchMediaHubUsersForProjects()
  },
  methods: {
    onMediaTypeFilterChange() {
      this.$emit("input", this.filters)
    },
    inputToDate(filters: MediaFilterQueryParams) {
      this.$emit("input", filters)
      this.endDateMenuRef?.save(filters.toDate)
    },
    inputFromDate(filters: MediaFilterQueryParams) {
      this.$emit("input", filters)
      this.startDateMenuRef?.save(filters?.fromDate)
    },
    onCameraChange() {
      this.$emit("input", this.filters)
    },
    async fetchMediaHubUsersForProjects(): Promise<MediaUsersResponsePayload> {
      let users = await EvercamApi.mediaHub.getAllMediaUsers(
        this.projectStore.selectedProjectExid
      )
      this.users = users.filter((user) => user.name !== "User Deleted")
    },
  },
})
