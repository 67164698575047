
import { Media, MediaStatus, MediaType } from "@evercam/shared/types/media"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"

export default Vue.extend({
  name: "MediaHubGallery",
  props: {
    mediaItems: {
      type: Array as PropType<Array<Media>>,
      default: () => [],
    },
    totalMediaItems: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      skeletonHeight: 0,
      MediaStatus,
    }
  },
  computed: {
    ...mapStores(useCameraStore),
    page: {
      get() {
        return this.currentPage
      },
      set(value) {
        this.$emit("page-change", value)
      },
    },
    totalPages() {
      return Math.ceil(this.totalMediaItems / 12)
    },
    computedMediaItems(): Array<Media> {
      return this.mediaItems.map((media: Media) => {
        return {
          ...media,
          thumbnailUrl: media.url,
        }
      })
    },
    pendingMediaItems() {
      return this.mediaItems.filter(
        (mediaItem) => mediaItem.status === MediaStatus.Pending
      )
    },
    routeProjectExid() {
      // @ts-ignore
      return camelizeKeys(this.$route.params)?.projectExid
    },
  },
  watch: {
    pendingMediaItems: {
      immediate: true,
      async handler(pendingMediaItems) {
        if (!pendingMediaItems?.length) {
          return
        }
        pendingMediaItems.forEach((item) => {
          const currentTime = new Date().getTime()
          let mediaCreatedTime = new Date(item.createdAt).getTime()
          const timeDifferenceInMilliseconds = Math.abs(
            currentTime - mediaCreatedTime
          )
          const timeDifferenceInMinutes = Math.floor(
            timeDifferenceInMilliseconds / (1000 * 60)
          )

          if (timeDifferenceInMinutes < 15) {
            const pendingMediaItemInterval = setInterval(
              () => this.getPendingMediaStatus(item, pendingMediaItemInterval),
              5000
            )
          }
        })
      },
    },
  },
  methods: {
    getDate(mediaItem, date) {
      return this.$moment.tz(date, mediaItem.cameraTimezone).format("L LT")
    },
    async getPendingMediaStatus(item, pendingMediaItemInterval) {
      let mediaItem = null as unknown as Media
      try {
        mediaItem = await EvercamApi.mediaHub.cShow(
          this.routeProjectExid,
          item.exid
        )
        if (mediaItem.status === MediaStatus.Completed) {
          this.$emit("refresh-mediahub")
          clearInterval(pendingMediaItemInterval)
        }
      } catch (error) {
        console.error(error)
      }
    },
    getMediaItemIcon(mediaItem: Media): string {
      if (mediaItem.type === MediaType.ExternalUrl) {
        return this.getExternalUrlIcon(mediaItem.url)
      }

      const defaultIcon = this.$device.isIos ? "fas fa-clock" : "fas fa-history"

      const iconMap = {
        [MediaType.Compare]: this.$device.isIos
          ? "fas fa-compress"
          : "fas fa-compress-arrows-alt",
        [MediaType.Clip]: "fas fa-cloud",
        [MediaType.LocalClip]: "fas fa-video",
        [MediaType.XRay]: "fas fa-x-ray",
        [MediaType.EditedImage]: "fas fa-images",
        [MediaType.File]: "fas fa-upload",
      }

      return iconMap?.[mediaItem.type] || defaultIcon
    },
    getExternalUrlIcon(url) {
      if (url?.includes("youtube.com")) {
        return "fab fa-youtube"
      }
      if (url?.includes("vimeo.com")) {
        return "fab fa-vimeo"
      }

      return "fas fa-link"
    },
    getFileExtention(mediaItem: Media): string {
      return mediaItem.fileName?.split(".").pop() || ""
    },
    isDocFile(mediaItem: Media): boolean {
      return ["pdf", "doc", "docx", "csv", "ppt"].includes(
        this.getFileExtention(mediaItem)
      )
    },
    getMediaFileIcon(mediaItem: Media): string {
      const fileIcons = {
        pdf: "fa-file-pdf",
        doc: "fa-file-word",
        docx: "fa-file-word",
        csv: "fa-file-csv",
        ppt: "fa-file-powerpoint",
      }
      const ext = this.getFileExtention(mediaItem)

      return fileIcons[ext] || "fa-file"
    },
    isPendingTimelapse(mediaItem: Media): boolean {
      return (
        mediaItem.type === MediaType.Timelapse &&
        mediaItem.status !== MediaStatus.Completed
      )
    },
    isUrlOrEditedImage(mediaItem: Media): boolean {
      return [MediaType.EditedImage, MediaType.ExternalUrl].includes(
        mediaItem.type
      )
    },
    getThumbnailUrl(mediaItem: Media): string | undefined {
      if (
        this.isPendingTimelapse(mediaItem) ||
        mediaItem.status === MediaStatus.Pending
      ) {
        return "/evercam-logo.png"
      }

      return this.$imgproxy.get360pResizedImageUrl(mediaItem?.thumbnailUrl)
    },
    handleImageError(e) {
      e.target.src = "/unavailable.jpg"
    },
    getMediaLink(mediaItem) {
      const mediaUrl = `/v2/projects/${this.routeProjectExid}/media-hub/${mediaItem.exid}`

      return mediaItem.status === MediaStatus.Pending ? null : mediaUrl
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `lighten-${hover ? "2" : "1"}`
      }

      return `darken-${hover ? "2" : "1"}`
    },
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubGoToPreviousPage)
    },
  },
})
